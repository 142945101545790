
import React, { Fragment,useEffect, useState  } from "react";
import Footer from "../../Pages/Footer";
import Menu from "../../Pages/Menu";
import agent from "./agent";
import Team from "../Team";
import TopBanner from "../TopBanner";

function OurClients()
{
    
    const [data,setData]=useState([]);
 
    useEffect(()=>{
        getData();
     
    },[])

    const getData = ()=> {
        agent.OurClient.list().then(response=>{
            setData(response);
        })
    }
    return(
        <Fragment>
        <Menu/>
        <TopBanner/>
        <div className="container-fluid gallery py-5 my-5 ">
            <div className="mx-auto text-center mb-5" style={{maxwidth: "900px"}}>
                <h5 className="section-title px-3">Our Clients</h5>
                           
            </div>
            <div className="tab-class text-center">

                <div className="tab-content">
                    <div id="GalleryTab-1" className="tab-pane fade show p-0 active">
                        <div className="row g-2">
                    {
        data && data.length>0 ?
        data.map((item,index )=>
        {
         
         
            return(

  
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-2" key={index}>
                                <div className="gallery-item h-100">
                                    <img src={item.imageSrc} className="img-fluid w-100 h-100 rounded" alt="Image"/>
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                          
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href={item.imageSrc} data-lightbox="gallery-1" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>

                        </div>
                        
                        )
                    })
                    :
                    <div>
                  
                    </div>
                }
                                            </div>
                          


                          </div>
                    
   
          </div>
           </div>
      </div>
                   
         { /*<Team/> */  }
        <Footer/>
        </Fragment>
    )
}

export default OurClients;