
import './Skeleton.css';
import Spinner from "../spinner";

const  SkeletonBanner=({type})=>
{
const classes=`skeleton ${type}`;

{

    return(
      <div className={classes}>
         <div className="carousel-header">
                <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active"></li>
                        <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
                        <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner" role="listbox">


                             <div  className={`carousel-item active`}>
                            <img src="" className="img-fluid" />
                            <div className="carousel-caption">
                                <div className="p-3" style={{maxwidth: "900px"}}>
                                  
                                    <h2 className="display-2 text-capitalize text-white mb-4">
                                      <Spinner/>
                                    </h2>
                                    <p className="mb-5 fs-5">
                                        
                                    </p>
                                 
                                    
                                </div>
                            </div>
                        </div>
   
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon btn bg-primary" aria-hidden="false"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                        <span className="carousel-control-next-icon btn bg-primary" aria-hidden="false"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
}
export default   SkeletonBanner;