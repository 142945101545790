import { Fragment } from "react/jsx-runtime";
import Menu from "./Menu";
import Footer from "./Footer";

 function Test(){
   
    return(
        
        <Fragment>
            <Menu/>
      
<h1>Test</h1>
            <Footer/>
        </Fragment>

    )
}
export default Test;