
import React, { Fragment,useEffect, useState  } from "react";
import Footer from "./Footer";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import agent from "../Components/api/agent";

import TopBanner from "../Components/TopBanner";

export default function Branches()
{

    
    const [data,setData]=useState([]);
 
    useEffect(()=>{
        getData();
     
    },[])

    const getData = ()=> {
        agent.Branches.list().then(response=>{
            setData(response);
        })
    }
    return(
        <Fragment>
            <Menu/>
            <TopBanner/>
        
        {/*-- Contact Start */}
        <div className="container-fluid contact bg-light py-5">
            <div className="container py-5">
                <div className="mx-auto text-center mb-5" style={{maxwidth: "900px"}}>
                    <h5 className="section-title px-3">Contact Us</h5>
                    <h1 className="mb-0">All Buranch</h1>
                </div>
                <div className="row g-5 ">
                {
                data.map((item,index )=>
              {
                
                     return(

                    

                    <div className="col-lg-4">
                    <h3 className="text-center bg-primary text-white rounded">{item.branchName}</h3>
                        <div className="bg-white rounded p-4">
                            
                            <div className="text-center mb-4">
                                <i className="fa fa-map-marker-alt fa-3x text-primary"></i>
                                <h4 className="text-primary">Address</h4>
                                <p className="mb-0">{item.address}</p>
                            </div>
                            <div className="text-center mb-4">
                                <i className="fa fa-phone-alt fa-3x text-primary mb-3"></i>
                                <h4 className="text-primary">Mobile</h4>
                                <p className="mb-0">{item.phone}</p>
                                
                            </div>
                           
                            <div className="text-center">
                                <i className="fa fa-envelope-open fa-3x text-primary mb-3"></i>
                                <h4 className="text-primary">Email</h4>
                                <p className="mb-0">{item.email}</p>
                             
                            </div>
                      
                        </div>
                       
                    </div>

                          
            )
        
        })
        }
                </div>
               
            </div>
            <span className="d-flex justify-content-center align-items-end"><Link to="/Contact"  class="btn btn-primary rounded-pill py-2 px-4">Back </Link></span>
        </div>
        <div className="container-fluid bg-oURService">
            <div className="container text-center py-2" style={{maxwidth: "900px"}}>
                <h3 className="text-white display-3 mb-4"></h3>
  
            </div>
        </div>
        {/*-- Contact End */}
            <Footer/>
        </Fragment>
    )
}