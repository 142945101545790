
import React, { Fragment,useEffect, useState  } from "react";
import agent from "./api/agent";
import UnderBanner from "./UnderBanner";
import TopBanner from "./TopBanner";
import Skeleton,{SkeletonTheme} from "react-loading-skeleton";
import SkeletonBanner from "./skeleton/skeletonBanner";
function Banner()
{
   

    const [data,setData]=useState([]);
    const [isLoading,setIsLoading]=useState(true);
     var i=0;
    useEffect(()=>{
        agent.Banner.list().then(response=>{
            setData(response);
            setIsLoading(false);
        })
        if (!isLoading) {
            const carouselElement = document.querySelector('#carouselId');
            const carouselInstance = new window.bootstrap.Carousel(carouselElement, {
              interval: 2000, // adjust interval as needed
              ride: 'carousel'
            });
          }
        }, [isLoading]);

    return(
        <Fragment>
            
        <div>

            <TopBanner/>
             <SkeletonTheme highlightColor="#e8ffd1">

            {
                isLoading
                ?
                <>
                <SkeletonBanner />
                </>
                :
            
                <div className="carousel-header">
                <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active"></li>
                        <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
                        <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner" role="listbox">
                    {
        
        data.map((item,index )=>
        {
         
          const active = index === 1 ? "active" : "";
         
            return(

                             <div key={index} className={`carousel-item  ${active}`}>
                            <img src={item.imageSrc} className="img-fluid" />
                            <div className="carousel-caption">
                                <div className="p-3" style={{maxwidth: "900px"}}>
                                  
                                    <h2 className="display-2 text-capitalize text-white mb-4">{item.title}</h2>
                                    <p className="mb-5 fs-5">
                                    
                                    </p>
                                 
                                    
                                </div>
                            </div>
                        </div>
                        
                        )
                    })
                    
                   
                }
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon btn bg-primary" aria-hidden="false"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                        <span className="carousel-control-next-icon btn bg-primary" aria-hidden="false"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            

           }
            
            </SkeletonTheme>
            
        <UnderBanner/>
        
             </div>
      
    
        </Fragment>
    )
}

export default Banner; 