
import React, { Fragment, useEffect, useState }  from "react";
import Menu from "../Pages/Menu";
import Footer from "../Pages/Footer";
import Table from'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function BannerList(){


        {/* Modal start  */}
        const [show, setShow] = useState(false);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
      
       {/*Insert Section feilds*/}
       const [title,setTitle]=useState('');
       const [createDate,setCreateDate]=useState('');
       const[imagePath,setImagePath]=useState('');

    const [data,setData]=useState([]);
  
    useEffect(()=>{
        getData();
    },[])

    const getData = ()=> {
        axios.get('https://localhost:44315/api/Banner')
        .then((result)=>{
            setData(result.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    const handleEdit=(id)=>{
        handleShow();
        axios.get(`https://localhost:44315/api/Banner/${id}`)
        .then((result)=>
        {
 
        }).catch((error)=>{
          toast.error(error);
        })

    }
    const handleDelete=(id)=>{
        if(window.confirm("Are you sure delete this office .."+id)==true)
        {
            axios.delete(`https://localhost:44315/api/Banner/${id}`)
            .then((result)=>{
            if(result.status===200)
            {
             
               toast.success('Office has been successfully Deleted');
               getData();
            }
          }).catch((error)=>{
            toast.error(error);
          })

        }
       
    }
    const handleUpdate=()=>{

       
    }
    const handleBannerSave=()=>{
       const url='https://localhost:44315/api/Banner';
       const data={
        "title": title,
        "createDate": createDate,
        'imagePath':imagePath,
       }
       axios.post(url,data)
       .then((result)=>{
        getData();
        clear();
        toast.success('Banner has successfully added');
       }).catch((error)=>{
        toast.error(error);
       })
    }
    const clear=()=>{
        setTitle('');
        setImagePath('');
        setCreateDate('');


    }
    return(
        
       <Fragment>

<Menu/>

       
        <div className="container-fluid contact bg-light py-5">
            <div className="container py-5">
       

<ToastContainer/>
<Container>
<h1>ManageBanner</h1>
      <Row>
        <Col>
        <input type="text" placeholder="Name" className="form-control" value={title} onChange={(e)=>setTitle(e.target.value)}  />
        </Col>
        <Col> 
        <input type="text" placeholder="Date" className="form-control" value={createDate} onChange={(e)=>setCreateDate(e.target.value)}/>
        </Col>
        <Col>
       
        </Col>
       <Col>
       <div className="card">
        <img  className="card-img-top"/>
        <div className="card-body">
         <div className="form-group">
         <input type="file" accept="image/*" className="form-control-file" value={imagePath} onChange={(e)=>setImagePath(e.target.value)}/>
         </div>
        </div>
       </div>
       </Col>
      </Row>
      <br/>

      <Row>
        <Col>
        <Button variant="secondary"  onClick={()=>handleBannerSave()}>Save</Button>
        </Col>
       
      </Row>
  
    <br/>
    <Table striped bordered  hover >
<thead>
    <tr>
        <th>Id</th>
        <th>title</th>
        <th>createDate</th>
        <th>Image</th>

        

        <th>Action</th>

    </tr>
</thead>
<tbody>
    {
        data && data.length>0 ?
        data.map((item,index )=>
        {

            return(
            <tr key={index}>
            <td>{item.id}</td>
            <td>{item.title}</td>
            <td>{item.createDate}</td>
            <td>{item.imagePath}</td>

            <td>
                <Button variant="danger" onClick={()=> handleDelete(item.id)}>Delete</Button>
                |
                <Button variant="primary" onClick={()=> handleEdit(item.id)}>Edit</Button>
               
            </td>
    
    
        </tr>
        )
        })
        :
        'Lodding....'
    }

</tbody>
    </Table>
    </Container>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modify /Update Office</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
        <Col>
      
        </Col>
      </Row>
      <br/>
      <Row>
        <Col>
      
        </Col>
      </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
            </div>
        </div>
        
<Footer/>

       </Fragment>
        
    )
}

export default BannerList