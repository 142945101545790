import React, {  Fragment, useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
function UnderBanner()
{

    const [tripType, setTripType] = useState('roundTrip');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [departureDate, setDepartureDate] = useState('');
    const [returnDate, setReturnDate] = useState('');
    const [passengers, setPassengers] = useState('');
    const [room, setRoom] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission
       clear();
    };
    const clear=()=>{
        setPassengers('');
        setFrom('');
        setTo('');
        setDepartureDate('');
        setRoom('');
        setReturnDate('');

    }
    return (
        <div className="container-fluid   bg-primary">
        <div className="container-fluid about ">
            <div className="container">
            <div className="container align-items-center justify-content-center">
                <div className="search-container border">
                    <Tabs
                    defaultActiveKey="flights "
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                    >
                       
                    <Tab eventKey="flights " title={<span><i className="fa fa-plane"></i> Flights</span>} >
                   
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="tripType" id="roundTrip" value="roundTrip" checked={tripType === 'roundTrip'} onChange={(e) => setTripType(e.target.value)} />
                                    <label className="form-check-label text-dark" htmlFor="roundTrip">Round trip</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="tripType" id="oneWay" value="oneWay" checked={tripType === 'oneWay'} onChange={(e) => setTripType(e.target.value)} />
                                    <label className="form-check-label text-dark" htmlFor="oneWay">One way</label>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-3">
                                <label htmlFor="from">From</label>
                                <input type="text" className="form-control" id="from" placeholder="From" required value={from} onChange={(e) => setFrom(e.target.value)} />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="to">To</label>
                                <input type="text" className="form-control" id="to" placeholder="Destination" required value={to} onChange={(e) => setTo(e.target.value)} />
                            </div>
                        
                            <div className="form-group col-md-3">
                                <label htmlFor="departureDate">Date</label>
                                <input type="date" className="form-control" id="departureDate" required value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
                            </div>
                            <div className="form-group col-md-2">
                                <label htmlFor="passengers">Passengers</label>
                                <input type="number" className="form-control" id="passengers" placeholder="Passenger"  value={passengers} onChange={(e) => setPassengers(e.target.value)} />
                            </div>
                            <div className="form-group col-md-1">
                                <br/>
                            <button type="submit" className="btn btn-primary btn-block">Search</button>
                            </div>
                        </div>

                       
                    </form>
                    </Tab>
                    <Tab eventKey="hotels "  title={<span><i className="fa fa-building"></i> Hotels</span>}>
                    <form onSubmit={handleSubmit}>

                        <div className="row">
                            <div className="form-group col-md-3">
                                <label htmlFor="from">Where to?</label>
                                <input type="text" className="form-control" id="from" placeholder="Where to" required value={from} onChange={(e) => setFrom(e.target.value)} />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="to">Check in</label>
                                <input type="date" className="form-control" id="departureDate" required value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
                            </div>
                        
                            <div className="form-group col-md-3">
                                <label htmlFor="departureDate">Check out</label>
                                <input type="date" className="form-control" id="returnDate" required  value={returnDate} onChange={(e) => setReturnDate(e.target.value)}  />
                            </div>
                            <div className="form-group col-md-2">
                                <label htmlFor="passengers">Guest and  Rooms</label>
                                <input type="number" className="form-control" id="room" placeholder="Guest" required value={room} onChange={(e) => setRoom(e.target.value)} />
                            </div>
                            <div className="form-group col-md-1">
                                <br/>
                            <button type="submit" className="btn btn-primary btn-block">Search</button>
                            </div>
                        </div>

                       
                    </form> 
                    </Tab>
                    <Tab eventKey="travel-Insurance"  title={<span><i className="fa fa-medkit"></i> Travel Insurance</span>}>
                    <form onSubmit={handleSubmit}>

                        <div className="row">
                            <div className="form-group col-md-3">
                                <label htmlFor="from">Adults</label>
                                <select id="passengers" className="form-control" value={passengers} onChange={(e) => setPassengers(e.target.value)}>
                                    <option>1 Adult</option>
                                    <option>2 Adult</option>
                    
                                </select>
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="to">children</label>
                                <select id="passengers" className="form-control" value={passengers} onChange={(e) => setPassengers(e.target.value)}>
                                    <option>0 Children</option>
                                    <option>1 Children</option>
                                    <option>2 Children</option>
                                    <option>3 Children</option>
                                    <option>4 Children</option>
                                    <option>5 Children</option>
                                    <option>6 Children</option>
                                    <option>7 Children</option>
                                    <option>8 Children</option>
                                    <option>9 Children</option>
                                    <option>10 Children</option>
                                </select>
                            </div>
                        
                            <div className="form-group col-md-3">
                                <label htmlFor="departureDate">My policy starts on</label>
                                <input type="date" className="form-control" id="departureDate" required value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
                            </div>
                            <div className="form-group col-md-2">
                                <label htmlFor="passengers">My policy finishes on </label>
                                <input type="date" className="form-control" id="returnDate" required  value={returnDate} onChange={(e) => setReturnDate(e.target.value)} />
                            </div>
                            <div className="form-group col-md-1">
                                <br/>
                            <button type="submit" className="btn btn-primary btn-block">Search</button>
                            </div>
                        </div>

                       
                    </form>
                    </Tab>
  
                    </Tabs>

                </div>
            </div>
        </div>
        </div>
        </div>
       
    );

}
export default UnderBanner;