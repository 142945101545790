
import Index from "./Pages/Index";

import About from "./Pages/About";
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import Contact from "./Pages/Contact";
import Gallery from "./Pages/Gallery";

import OfficeList from "./Components/OfficeList";
import BannerList from "./Components/BannerList";
import AboutDetails from "./Pages/AboutDetails";
import ScrollToTop from "./Components/ScrollToTop";
import Packages from "./Components/Packages";
import OurClients from "./Components/api/OurClients";
import News from "./Pages/News";
import OurServices from "./Pages/OurServices";
import NewsDetails from "./Pages/NewsDetails";
import Intoffice from "./Pages/Intoffice";
import Privacy from "./Pages/Privacy";
import Branches from "./Pages/Branches";

import Test from "./Pages/Test";
import PackageDetails from "./Components/PackageDetails";
import Events from "./Pages/Events";
import EventDetails from "./Pages/EventDetails";
import SinglePackages from "./Components/SinglePackages";
import NotFound from "./Pages/NotFound";


function App() {
  return (
    <div className="App">

      <Router>
      <ScrollToTop/>
      
        <Routes>
          <Route path="/" element={<Index/>} />
          <Route path="About" element={<About />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="Gallery" element={<Gallery />} />
          <Route path="OfficeList" element={<OfficeList/>}/>
          <Route path="BannerList" element={<BannerList/>}/>
          <Route path="AboutDetails" element={<AboutDetails/>}/>    
          <Route path="Packages" element={<Packages/>}/>
          <Route path="OurClients" element={<OurClients/>}/>
          <Route path="Events/:id" element={<Events/>}/>
          <Route path="News/:id" element={<News/>}/>

          <Route path="EventDetails/:id" element={<EventDetails/>}/>

          <Route path="NewsDetails/:id" element={<NewsDetails/>}/>

          <Route path="SinglePackages/:id" element={<SinglePackages/>}/>


          <Route path="Intoffice/:id" element={<Intoffice/>}/>
 
          <Route path="OurServices/:id" element={<OurServices/>}/>

          <Route path="Privacy" element={<Privacy/>}/>
          <Route path="Branches" element={<Branches/>}/>
          <Route path="PackageDetails/:id" element={<PackageDetails/>}/>
          
          <Route path="Test/:id" element={<Test/>}/>
          <Route path="*" element={<NotFound/>}/>

        </Routes>
      </Router>
  
    </div>
  );
}

export default App;
