
import React, { Fragment,useEffect, useState  } from "react";
import Footer from "./Footer";
import Menu from "./Menu";
import agent from "../Components/api/agent";
import Team from "../Components/Team";
import TopBanner from "../Components/TopBanner";

function AboutDetails()
{

    const [data,setData]=useState([]);
 
   
    useEffect(()=>{
      agent.AboutUs.list().then(response=>{
        setData(response);
      })
     
    },[])


    return(
        <Fragment>
                      {/*-- Template Stylesheet */}

            <Menu />
            {/*-- Header Start */}
            <TopBanner/>
        <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5" style={{maxwidth: "900px"}}>
                <h3 className="text-white display-3 mb-4">About Us</h3>
            </div>
        </div>
        {/*-- Header End */}
                   {/*-- About Start */}
       
            <div className="container py-5">
                <div className="row g-12 align-items-center">

                    <div className="col-lg-12" style={{background: `linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .8))`, backgroundImage: `url(img/about-img-1.png)`}}>
                      
                        <h1 className="mb-4">Welcome to <span className="text-primary">SkyTravels</span></h1>
                        <p className="mb-4 " >
                        <div dangerouslySetInnerHTML={{__html: data.remarks}} />
                       
                        </p>

                    </div>
                </div>
            </div>
        
        {/*-- About End */}

        {/*-- Travel Guide End */}
        <Footer />
        </Fragment>
    )
}

export default AboutDetails; 