import React, { Fragment,useState,useEffect } from "react";
import { Link } from "react-router-dom";
import agent from "../Components/api/agent";
function Footer(){
    const[data,setData]=useState([]);
    useEffect(()=>{
         agent.AboutUs.list().then(response=>{
            setData(response);
         })
    },[])
    return(
        <Fragment>
           
        {/*- Footer Start -*/}
        <div className="container-fluid footer py-5">
            <div className="">
                <div className="row">
                    
                <div className="col-md-6 col-lg-6 col-xl-4">
                        <div className="footer-item d-flex flex-column">
                            <h6 className="mb-4 text-white footerLogo">
                            <img src="/assets/img/logo1.png" className="img-fluid w-100 h-100" alt=""/>
                            </h6>
                            <span  className="LongTextThreeDotAboutSectionFooter mb-4 text-white">
                                 
                            <div dangerouslySetInnerHTML={{__html: data.remarks}} />
                            </span>
   
                            <div className="d-flex align-items-center">
                            <Link to="/AboutDetails"><i className="fas fa-angle-right me-2"></i>ReadMore...</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-6 col-xl-4">
                        <div className="footer-item d-flex flex-column">
                            <h4 className="mb-4 text-white">Get In Touch</h4>
                            <div className="text-white"><i className="fas fa-home me-2"></i> {data.address}</div>
                            <div className="text-white"><i className="fas fa-envelope me-2 py-2"></i>{data.email}</div>
                            <div className="text-white"><i className="fas fa-phone me-2 py-2"></i>{data.phone}</div>

                            <div className="d-flex align-items-center py-2">
                                <i className="fas fa-share fa-2x text-white me-2 "></i>
                                <a className="btn-square btn btn-primary rounded-circle mx-1" target="_blank" href={data.facebook}><i className="fab fa-facebook-f"></i></a>
                                <a className="btn-square btn btn-primary rounded-circle mx-1" target="_blank" href={data.twitter}><i className="fab fa-twitter"></i></a>
                                <a className="btn-square btn btn-primary rounded-circle mx-1" target="_blank" href={data.instagram}><i className="fab fa-instagram"></i></a>
                                <a href={data.linkedin} target="_blank" className="btn-square btn btn-primary rounded-circle mx-1"><i className="fab fa-linkedin-in"></i></a>
                               


                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-6 col-xl-4">
                        <div className="footer-item d-flex flex-column">
                            <h4 className="mb-4 text-white">USEFUL LINKS</h4>
                            <Link to="/About"><i className="fas fa-angle-right me-2"></i>About Us</Link>
                           {/* <Link to="/Packages"><i className="fas fa-angle-right me-2"></i>Tour Packages</Link> */}
                            <Link to="/Contact"><i className="fas fa-angle-right me-2"></i> Contact Us</Link>
                            <Link to="/Privacy"><i className="fas fa-angle-right me-2"></i> Privacy Policy</Link>
                          {/*  <Link to="https://Admin.skytravels.com/Identity/Account/Login" target="_blank"><i className="fas fa-angle-right me-2"></i>Login</Link>*/}


                        </div>
                    </div>
    
                </div>
            </div>
        </div>
        {/*- Footer End -*/}
        
        {/*- Copyright Start -*/}
        <div className="container-fluid copyright text-body py-4">
            <div className="container">
                <div className="row g-4 align-items-center">
                    <div className="col-md-6 text-center text-md-end mb-md-0">
                        <i className="fas fa-copyright me-2"></i><a className="text-white" href="#"></a> 2024 SkyTravels.com. All Rights reserved.
                    </div>

                </div>
            </div>
        </div>
        {/*- Copyright End -*/}

        {/*-- Back to Top -*/}
        <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up"></i></a>   

        

        </Fragment>
    )
}

export default Footer; 