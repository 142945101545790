
import React, {  Fragment, useEffect, useState } from "react";
import Menu from "./Menu";
import TopBanner from "../Components/TopBanner";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import agent from "../Components/api/agent";
import { Link } from "react-router-dom";
function News()
{

    const param= useParams();
    const [data,setData]=useState([]);


    useEffect(()=>{
        getData();
     
    },[])

    const getData = ()=> {
        agent.News.NewsGroup(param.id).then(response=>{
            setData(response);
        })
    }
    return(
        <Fragment>
      <Menu/>
      <TopBanner/>
      

        {/*- Explore Tour Start -*/}
        <div className="container-fluid bg-oURService">
            <div className="container text-center py-2" style={{maxwidth: "900px"}}>
                <h3 className="text-white display-3 mb-4">News</h3>
  
            </div>
        </div>
        <div className="container-fluid blog py-5">
        <div className="mx-auto text-center" style={{maxwidth: "900px"}}>
                <h5 className="section-title">All News</h5>
                
            </div>
            <div className="container py-5">

                <div className="row g-4">
                    {
                data.map((item,index )=>
              {
                
                const createDate = new Date(item.createDate);
                const formattedDate = createDate.toLocaleDateString();
            return(
                <div className="col-lg-3 col-md-4">
                <div className="blog-item">
                    <div className="blog-img">
                
                        <div className="blog-img-inner">
                            <img className="img-fluid w-100  rounded-top" src={item.imageSrc} alt="Image" style={{maxheight:"200px !important"}}/>
                            <div className="blog-icon">
                                <a href="#" className="my-auto"><i className="fas fa-link fa-2x text-white"></i></a>
                            </div>
                        </div>
                        <div className="blog-info d-flex align-items-center border border-start-0 border-end-0">
                            <small className="flex-fill text-center border-end py-2"><i className="fa fa-calendar-alt text-primary me-2"></i>{formattedDate}</small>
                        </div>
                    </div>
                  
                    <div className="LongTextNewstitle blog-content border border-top-0 rounded-bottom p-4">
                    <Link to={`/NewsDetails/${item.id}`} >
                        <a href="#" className="h5">{item.title}</a>
                        </Link>
                      
                    </div>
                </div>
            </div>

                
            )
        
        })
        }
                    </div>
                        </div>
                        <span className="d-flex justify-content-center align-items-end"><Link to="/"  class="btn btn-primary rounded-pill py-2 px-4">Back</Link></span>
                    </div>
 
        {/*- Explore Tour Start -*/}
        
        {/*- Subscribe Start -*/}
        <div className="container-fluid subscribe py-5">
            <div className="container text-center py-5">
                <div className="mx-auto text-center" style={{mawidth: "900px"}}>
                    <h5 className="subscribe-title px-3">Subscribe</h5>
                    <h1 className="text-white mb-4">Wanna Know Our Latest Offers And Deals Just Subcscribe Here</h1>
                  
                    <div className="position-relative mx-auto">
                        <input className="form-control border-primary rounded-pill w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email"/>
                        <button type="button" className="btn btn-primary rounded-pill position-absolute top-0 end-0 py-2 px-4 mt-2 me-2">Subscribe</button>
                    </div>
                </div>
            </div>
        </div>
        {/*- Subscribe End -*/}
                   
<Footer/>
        </Fragment>
    )
}

export default News;