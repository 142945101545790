import React, { Fragment, useEffect, useState } from "react";
import Footer from "./Footer";
import Menu from "./Menu";
import Banner from "../Components/Banner";
import agent from "../Components/api/agent";
import { Link } from "react-router-dom";
import CountryLike from "../Components/CountryLike";
import { SkeletonTheme } from "react-loading-skeleton";
import SkeletonLastUpdate from "../Components/skeleton/skeletonLastUpdate";
function Index() {
  const [isLoading,setIsLoading]=useState(true);
  const [data, setData] = useState([]);


  useEffect(() => {
    getData();


  }, []);

  const getData = () => {
    agent.News.list().then((response) => {
      setData(response);
      setIsLoading(false);
    });
  };



  return (
    <Fragment>
      <Menu />
      {/*-- Carousel Start --*/}

      <Banner />
  

      {/*- Country You Like Start -*/}
     {/*  <CountryLike/>*/}
      {/*Country you like end*/}
      {/*
      <SkeletonTheme  highlightColor="#e8ffd1">
             {
                    isLoading
                    ?
                    <>
                    <SkeletonLastUpdate/>
                    </>
                    :

        <div className="container-fluid ExploreTour py-5">
        <div className="container">
       
      
        <h4 className=" px-3  ">Latest updates</h4>
          <div className="container-fluid blog py-5">
          
            <div className="row g-4  ">
              {data.map((item, index) => {
                const createDate = new Date(item.createDate);
                const formattedDate = createDate.toLocaleDateString();
                return (
                  <div className="col-md-4" key={index}>
                    <div className="blog-item">
                      <Link to={`/NewsDetails/${item.id}`}>
                        <div className="blog-img ">
                          <div className="blog-img-inner">
                            <img
                              className="img-fluid w-100  rounded-top "
                              src={item.imageSrc}
                              alt="Image"
                              style={{ maxheight: "200px !important" }}
                            />
                            <div className="blog-icon">
                              <a href="#" className="my-auto">
                                <i className="fas fa-link fa-2x text-white"></i>
                              </a>
                            </div>
                          </div>
                          <div className="blog-info d-flex align-items-center border border-start-0 border-end-0">
                            <small className="flex-fill text-center border-end py-2">
                              <i className="fa fa-calendar-alt text-primary me-2"></i>
                              {formattedDate}
                            </small>
                          </div>
                        </div>

                        <div className="LongTextNewstitle blog-content border border-top-0 rounded-bottom p-4">
                          <a href="#" className="h5">
                            {item.title}
                          </a>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
               
            </div>
          
          </div>
          <span className="d-flex justify-content-center align-items-end"><Link to="/News/1"  className="btn btn-primary rounded-pill py-2 px-4">Load More...</Link></span>
        </div>
      </div>
}
</SkeletonTheme>
*/}
      {/*- Explore Tour  End -*/}

      {/*- Subscribe Start -*/}
      <div className="container-fluid bg-oURService">
            <div className="container text-center py-5" style={{maxwidth: "900px"}}>
                <h3 className="text-white display-3 mb-4"></h3>
  
            </div>
        </div>
      {/*- Subscribe End -*/}
      <Footer />
    </Fragment>
  );
}

export default Index;
