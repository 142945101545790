import React, {  Fragment, useEffect, useState } from "react";
import Menu from "../Pages/Menu";
import TopBanner from "./TopBanner";
import Footer from "../Pages/Footer";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import agent from "../Components/api/agent";
export default function PackageDetails(){
    const param= useParams();


    const [data,setData]=useState([]);
    const [image,setGallery]=useState([]);
    const[price,setPrice]=useState([]);
    
     
    useEffect(()=>{
        getData();
        getGallery();
        getPackagePrice();
    },[])
    
    const getData = ()=> {
       agent.TourPackage.Details(param.id).then(response=>{
        setData(response);
       })
}
    
    const getGallery=()=> {
       agent.TourPackage.PackageGallery(param.id).then(response=>{
        setGallery(response);
       })
    }
    const getPackagePrice=()=> {
        agent.TourPackage.PackagePrice(param.id).then(response=>{
            setPrice(response);
        })
    }
    return(
        
        <Fragment>
            <Menu/>
        <TopBanner/>
        <div className="container-fluid blog py-5">
            <div className="container py-5">

                <div className="row g-4 justify-content-center">
                <div className="mx-auto text-center mb-5" style={{maxwidth: "900px"}}>
                    <h5 className="section-title px-3 ">Packages Details</h5>
                    <h1 className="mb-0">Awesome Packages</h1>
                </div>
                        <div className="col-lg-12 col-md-12">
                        <div className="blog-item">
                        <hr className="m-1"/>
                            <div className="blog-content border border-top-0 rounded-bottom p-4">
                            
                            <a href="#" className="h4">{data.title}</a>
                                <p className="mb-3">{data.totalNight}N /{data.totalDay}D 
                            
                                </p>
                               
                                
                                <span>Includes</span>
                                
                                <div className="col-md-4 pt-3">
                               
                                <div className="d-flex">
                                <div className="btn-group">
                               
                                    <a className="btn-square btn btn-primary rounded-circle mx-1">
                                    <i className="fa fa-plane"></i>
                                    </a>
                                   
                                    <div className="text-center">{data.isflight? "Flight":""}</div>
                                </div>
                                <div className="btn-group">
                                    <a className="btn-square btn btn-primary rounded-circle mx-1">
                                    <i className="fa fa-car"></i>
                                    </a>
                                    <div className="text-center">{data.isTransports? "Transports":""}</div>
                                </div>
                                <div className="btn-group">
                                    <a className="btn-square btn btn-primary rounded-circle mx-1">
                                    <i className="fa fa-building"></i>
                                    </a>
                                    <div className="text-center">{data.ishotels? "Hotels":""}</div>
                                </div>
                                <div className="btn-group">
                                    <a className="btn-square btn btn-primary rounded-circle mx-1">
                                    <i className="fa fa-plane"></i>
                                    </a>
                                    <div className="text-center">{data.isinsurance? "Insurance":""}</div>
                                </div>
                                <div className="btn-group">
                                    <a className="btn-square btn btn-primary rounded-circle mx-1">
                                    <i className="fa fa-binoculars"></i>
                                    </a>
                                    <div className="text-center" >{data.isSightseeing? "Sightseeing":""}</div>
                                </div>
                                </div>
                                </div>
                                <br/>
                               
                               <div dangerouslySetInnerHTML={{__html: data.remarks}}></div>
                               <hr/>
                               <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a href="#home" className="nav-link active" data-bs-toggle="tab">Itinerary</a>
                            </li>
                            <li className="nav-item">
                                <a href="#profile" className="nav-link" data-bs-toggle="tab">Includes</a>
                            </li>
                            <li className="nav-item">
                                <a href="#messages" className="nav-link" data-bs-toggle="tab">Excludes</a>
                            </li>
                            <li className="nav-item">
                                <a href="#price" className="nav-link" data-bs-toggle="tab">Package Price</a>
                            </li>
                            <li className="nav-item">
                                <a href="#policy" className="nav-link" data-bs-toggle="tab">Payment & Cancellation Policy</a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="home">
                                <div className="p pt-2 px-3" dangerouslySetInnerHTML={{__html:data.itinerary}}></div>
                            </div>
                            <div className="tab-pane fade" id="profile">
                                <p>{data.includes}</p>
                            </div>
                            <div className="tab-pane fade" id="messages">
                                <p>{data.excludes}</p>
                            </div>
                            
                            <div className="tab-pane fade" id="price">
                            
                                
                                        <table className="table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            <th>Currency</th>
                                            <th>Meal Plan</th>
                                            <th>Adult Single</th>
                                            <th>Adult Double-Occ</th>
                                            <th>Adult Triple-Occ	</th>
                                            <th>Child With Bed</th>
                                            <th>Child Without Bed	</th>
                                            <th>Infant</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{price.currency}</td>
                                                <td>{price.mealPlan}</td>
                                                <td>{price.adultSingle}</td>
                                                <td>{price.adultDouble}</td>
                                                <td>{price.adultTriple}</td>
                                                <td>{price.childWithBed}</td>
                                                <td>{price.childWithoutBed}</td>
                                                <td>{price.infant}</td>

                                            </tr>
                                        </tbody>
                                        </table>
                                
                                    
                            </div>
                            
                            <div className="tab-pane fade" id="policy">
                                <p>{data.paymentCancelPolicy}</p>
                            </div>
                        </div>
                            </div>
                            
                        </div>
                        <div className="container-fluid gallery">

                    


    <div id="GalleryTab-1" >

        <div className="row g-2">
        <a href="#" className="h4 pt-3"></a>
        {
image.map((item,index )=>
{

return(
<div className="col-sm-6 col-md-6 col-lg-4 col-xl-2" key={index}>
<div className="gallery-item h-100">
    <img src={item.imageSrc} className="img-fluid w-100 h-100 rounded" alt="Image"/>
    <div className="gallery-content">
        <div className="gallery-info">
          
        </div>
    </div>
    <div className="gallery-plus-icon">
        <a href={item.imageSrc} data-lightbox="gallery-1" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
    </div>
</div>

</div>
  )
}
)}
         </div>
          
       

</div>

</div>
                       <div className="text-center pt-3">
                       <Link to='/Packages' className="btn btn-primary rounded-pill py-2 px-4">
                                Back
                                </Link>
                       </div>

                       
                    </div>

              
              

                </div>
            </div>
        </div>
   
            <Footer/>
        </Fragment>
    )
}