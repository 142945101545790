import React, {  Fragment, useEffect, useState } from "react";
import Menu from "../Pages/Menu"
import Footer from "../Pages/Footer"
import TopBanner from "./TopBanner"
import agent from "../Components/api/agent"
import { Link } from "react-router-dom";
export default function Packages(){
    
    const [data,setData]=useState([]);


    useEffect(()=>{
        getData();
     
    },[])

    const getData = ()=> {
        agent.TourPackage.list().then(response=>{
            setData(response);
        })
    }
    return(
        <Fragment>
            <Menu/>
            <TopBanner/>
           
        <div className="container-fluid blog py-5">
            <div className="container py-5">

                <div className="row g-4 justify-content-center">
                   {
                     data.map((item,index )=>
                    {
                      return(
                        <div className="col-lg-4 col-md-6">
                        <div className="blog-item">
                            <div className="blog-img">
                                <div className="blog-img-inner">
                                    <img className="img-fluid w-100 rounded-top PackageImage" src={item.imageSrc}  alt="Image"/>
                                    <div className="blog-icon">
                                        <a href="#" className="my-auto"><i className="fas fa-link fa-2x text-white"></i></a>
                                    </div>
                                </div>
                                <div className="blog-info d-flex align-items-center border border-start-0 border-end-0">
                                    <small className="flex-fill text-center border-end py-2"><i className=" text-primary me-2"></i></small>
                                    <a href="#" className="btn-hover flex-fill text-center text-white border-end py-2"><i className=" text-primary me-2"></i></a>
                                    <a href="#" className="btn-hover flex-fill text-center text-white py-2" style={{backgroundcolor: "#13357b"}}><i className=" text-primary me-2"></i>{item.totalNight}N/{item.totalDay}D</a>
                                </div>
                            </div>
                            <div className="blog-content border border-top-0 rounded-bottom p-4" key={index}>
                                <p className="mb-3">{item.totalNight}N 
                            
                                </p>
                                <a href="#" className="h4">{item.title}</a>
                                <br/>  <br/>
                                <div className="d-flex align-items-center">
                                <div className="btn-group">
                                    <a className="btn-square btn btn-primary rounded-circle mx-1">
                                    <i className="fa fa-plane"></i>
                                    </a>
                                    <br/>
                                    <div className="text-center">{item.isflight? "Flight":""}</div>
                                </div>
                                <div className="btn-group">
                                    <a className="btn-square btn btn-primary rounded-circle mx-1">
                                    <i className="fa fa-car"></i>
                                    </a>
                                    <div className="text-center">{item.isTransports? "Transports":""}</div>
                                </div>
                                <div className="btn-group">
                                    <a className="btn-square btn btn-primary rounded-circle mx-1">
                                    <i className="fa fa-building"></i>
                                    </a>
                                    <div className="text-center">{item.ishotels? "Hotels":""}</div>
                                </div>
                                <div className="btn-group">
                                    <a className="btn-square btn btn-primary rounded-circle mx-1">
                                    <i className="fa fa-plane"></i>
                                    </a>
                                    <div className="text-center">{item.isinsurance? "Insurance":""}</div>
                                </div>
                                <div className="btn-group">
                                    <a className="btn-square btn btn-primary rounded-circle mx-1">
                                    <i className="fa fa-binoculars"></i>
                                    </a>
                                    <div className="text-center" >{item.isSightseeing? "Sightseeing":""}</div>
                                </div>
                                </div>
                                <br/>
                                <Link to={`/PackageDetails/${item.id}`} className="btn btn-primary rounded-pill py-2 px-4">
                                View Details
                                </Link>
                               
                            </div>
                        </div>
                    </div>

                      )
                    })
                   }
              

                </div>
            </div>
        </div>
   

           <Footer/>
        </Fragment>
    )
}

