import React, { Fragment } from "react";
import Menu from "./Menu";
import Footer from "./Footer";
import TopBanner from "../Components/TopBanner";

function Gallery(){
    return(
        <Fragment>

<Menu/>
<TopBanner/>
        {/*-- Header Start */}
        <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5" style={{maxwidth: "900px"}}>
                <h3 className="text-white display-3 mb-4">Our Gallery</h3>
                <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li className="breadcrumb-item"><a href="#">Pages</a></li>
                    <li className="breadcrumb-item active text-white">Gallery</li>
                </ol>    
            </div>
        </div>
        {/*-- Header End */}

        {/*-- Gallery Start */}
        <div className="container-fluid gallery py-5 my-5">
            <div className="mx-auto text-center mb-5" style={{maxwidth: "900px"}}>
                <h5 className="section-title px-3">Our Gallery</h5>
                <h1 className="mb-4">Tourism & Traveling Gallery.</h1>
                <p className="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum tempore nam, architecto doloremque velit explicabo? Voluptate sunt eveniet fuga eligendi! Expedita laudantium fugiat corrupti eum cum repellat a laborum quasi.
                </p>
            </div>
            <div className="tab-class text-center">
                <ul className="nav nav-pills d-inline-flex justify-content-center mb-5">
                    <li className="nav-item">
                        <a className="d-flex mx-3 py-2 border border-primary bg-light rounded-pill active" data-bs-toggle="pill" href="#GalleryTab-1">
                            <span className="text-dark" style={{width: "150px"}}>All</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="d-flex py-2 mx-3 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#GalleryTab-2">
                            <span className="text-dark" style={{width: "150px"}}>World tour</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#GalleryTab-3">
                            <span className="text-dark" style={{width: "150px"}}>Ocean Tour</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#GalleryTab-4">
                            <span className="text-dark" style={{width: "150px"}}>Summer Tour</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="d-flex mx-3 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#GalleryTab-5">
                            <span className="text-dark" style={{width: "150px"}}>Sport Tour</span>
                        </a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div id="GalleryTab-1" className="tab-pane fade show p-0 active">
                        <div className="row g-2">
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-1.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-1.jpg" data-lightbox="gallery-1" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-2.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-2.jpg" data-lightbox="gallery-2" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-3.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-3.jpg" data-lightbox="gallery-3" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-4.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-4.jpg" data-lightbox="gallery-4" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-5.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-5.jpg" data-lightbox="gallery-5" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-6.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-6.jpg" data-lightbox="gallery-6" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-7.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-7.jpg" data-lightbox="gallery-7" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-2">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-8.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-8.jpg" data-lightbox="gallery-8" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-9.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-9.jpg" data-lightbox="gallery-9" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-2">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-10.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-10.jpg" data-lightbox="gallery-10" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="GalleryTab-2" className="tab-pane fade show p-0">
                        <div className="row g-2">
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-2.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-2.jpg" data-lightbox="gallery-2" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-3.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-3.jpg" data-lightbox="gallery-3" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="GalleryTab-3" className="tab-pane fade show p-0">
                        <div className="row g-2">
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-2.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-2.jpg" data-lightbox="gallery-2" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-3.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-3.jpg" data-lightbox="gallery-3" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="GalleryTab-4" className="tab-pane fade show p-0">
                        <div className="row g-2">
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-2.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-2.jpg" data-lightbox="gallery-2" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-3.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-3.jpg" data-lightbox="gallery-3" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="GalleryTab-5" className="tab-pane fade show p-0">
                        <div className="row g-2">
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-2.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-2.jpg" data-lightbox="gallery-2" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                                <div className="gallery-item h-100">
                                    <img src="assets/img/gallery-3.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                                    <div className="gallery-content">
                                        <div className="gallery-info">
                                            <h5 className="text-white text-uppercase mb-2">World Tour</h5>
                                            <a href="#" className="btn-hover text-white">View All Place <i className="fa fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                    <div className="gallery-plus-icon">
                                        <a href="assets/img/gallery-3.jpg" data-lightbox="gallery-3" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*-- Gallery End */}

        {/*-- Subscribe Start */}
        <div className="container-fluid subscribe py-5">
            <div className="container text-center py-5">
                <div className="mx-auto text-center" style={{maxwidth: "900px"}}>
                    <h5 className="subscribe-title px-3">Subscribe</h5>
                    <h1 className="text-white mb-4">Our Newsletter</h1>
                    <p className="text-white mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum tempore nam, architecto doloremque velit explicabo? Voluptate sunt eveniet fuga eligendi! Expedita laudantium fugiat corrupti eum cum repellat a laborum quasi.
                    </p>
                    <div className="position-relative mx-auto">
                        <input className="form-control border-primary rounded-pill w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email"/>
                        <button type="button" className="btn btn-primary rounded-pill position-absolute top-0 end-0 py-2 px-4 mt-2 me-2">Subscribe</button>
                    </div>
                </div>
            </div>
        </div>
        {/*-- Subscribe End */}
        <Footer/>
        </Fragment>
       
    )
}

export default Gallery;