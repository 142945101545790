import React, {  Fragment, useEffect, useState } from "react";
import Menu from "./Menu";
import TopBanner from "../Components/TopBanner";
import Footer from "./Footer";
import agent from "../Components/api/agent"
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";



export default function OurServices()
{

    const param = useParams();
    const [id, setId] = useState(param.id || 0); // Initialize id state with param.id
    const [data,setData]=useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(()=>{
        getData();
     
    },[id])

  

    const getData = ()=> {
        try{
            agent.OurServices.OurServicessByid(param.id).then(response=>{
                setData(response);
            })
        }
        catch(error)
        {
            console.error('Error fetching data:', error);
        }
        finally{
            setIsLoading(false);
        }

     
    }
        // Function to update id state when param.id changes
        useEffect(() => {
            setId(param.id || 0);
        }, [param.id]);

    if (isLoading) return <span><Spinner/></span>;
          
    if(!data) return <h2>Error fetching data</h2>
    return(
        <Fragment>
            <Menu/>
            <TopBanner/>

             {/*- Explore Tour Start -*/}
        <div className="container-fluid ExploreTour py-5">
        <div className="container-fluid about py-5">
            <div className="container">
                <div className="row g-5 align-items-center border rounded" style={{backgroundcolor: "#fbfbfb !important"}}>
                   
                   
                        <h5 className=" pe-3">{data.title}</h5>
                       
                        <p className="mb-4" >
                        <div dangerouslySetInnerHTML={{__html: data.remarks}} />
                       
                        </p>
   
                 
                </div>
            </div>
        </div>
        </div>
        {/*- Explore Tour Start -*/}
            <Footer/>
        </Fragment>
    )
}