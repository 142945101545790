import React, { Fragment,useState,useEffect } from "react";
import Footer from "./Footer";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import agent from "../Components/api/agent";
import TopBanner from "../Components/TopBanner";
import Team from "../Components/Team";
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function Contact(){
    const [isLoading, setIsLoading] = useState(false);
    const[data,setData]=useState([]);
    useEffect(()=>{
         agent.AboutUs.list().then(response=>{
            setData(response);
         })
    },[])

    const [formData, setFormData] = useState({
        username: '',
        email: ''
      })
    
      const [errors, setErrors] = useState({})
    
      const handleChange =  (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, [name] : value
        })
      }
    
      const handleSubmit = async (e) => {
        e.preventDefault()
        
        const validationErrors = {}
        if(!formData.username.trim()) {
            validationErrors.username = "username is required"
        }
    
        if(!formData.email.trim()) {
            validationErrors.email = "email is required"
        } else if(!/\S+@\S+\.\S+/.test(formData.email)){
            validationErrors.email = "email is not valid"
        }

     
        setErrors(validationErrors)

        setIsLoading(true);
       
       const url='https://Admin.skytravels.com/api/ContactUs';
        //const url='http://localhost:5257/api/ContactUs';
        //const url='http://localhost:809/api/ContactUs';

        const data={
         "name": name,
         "email": email,
         "subject": subject,
         "messages": messages
        }
      
        axios.post(url,data)
        .then(async (result)=>
        {
           
         clear();
         await new Promise(resolve => setTimeout(resolve, 2000));
         // Once the operation is completed, enable the button
         setIsLoading(false);
 
         toast.success('Message has been successfully Send');
         
        }).catch((error)=>{
         toast.error(error);
         setIsLoading(false); // Ensure the button is enabled in case of error
        })
    
      }
    
      {/*Insert Section feilds*/}
      const [name,setName]=useState('');
      const[email,setEmail]=useState('');
      const[subject,setSubject]=useState();
      const[messages,setMessages]=useState('');


     const clear=()=>{
         setName('');
         setEmail('');
         setSubject('');
         setMessages('');
 
     }
    return(
        <Fragment>
            <Menu/>
            <TopBanner/>
            <ToastContainer/>
             {/*-- Header Start */}
        <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5" style={{maxwidth: "900px"}}>
                <h3 className="text-white display-3 mb-4">Contact Us</h3>
  
            </div>
        </div>
        {/*-- Header End */}

        {/*-- Contact Start */}
        <div className="container-fluid contact bg-light py-5">
            <div className="container py-5">
                <div className="mx-auto text-center mb-5" style={{maxwidth: "900px"}}>
                    <h5 className="section-title px-3">Contact Us</h5>
                    <h1 className="mb-0">Contact For Any Query</h1>
                </div>
                <div className="row g-5 ">
                    <div className="col-lg-4">

                        <div className="bg-white rounded p-4">
                            
                            <div className="text-center mb-4">
                                <i className="fa fa-map-marker-alt fa-3x text-primary"></i>
                                <h4 className="text-primary">Address</h4>
                                <p className="mb-0">{data.address}</p>
                            </div>
                            <div className="text-center mb-4">
                                <i className="fa fa-phone-alt fa-3x text-primary mb-3"></i>
                                <h4 className="text-primary">Mobile</h4>
                                <p className="mb-0">{data.phone}</p>
                                
                            </div>
                           
                            <div className="text-center">
                                <i className="fa fa-envelope-open fa-3x text-primary mb-3"></i>
                                <h4 className="text-primary">Email</h4>
                                <p className="mb-0">{data.email}</p>
                             
                            </div>
                      
                        </div>
                       <br/>
                       
                        <span className="d-flex justify-content-center align-items-end"><Link to="/Branches"  class="btn btn-primary rounded-pill py-2 px-4">View All Branches </Link></span>
                    </div>
                    <div className="col-lg-8">
                        <h3 className="mb-2">Send us a message</h3>
                        
                        <form onSubmit={handleSubmit} >
                            <div className="row g-3">
                                <div className="col-md-6">
                                <span className="text-danger">*</span>
                                    <div className="form-floating">
                                    
                                        <input type="text" className="form-control border-0" required  placeholder="Your Name"  value={name} onChange={(e)=>setName(e.target.value)} />
                                        <label for="name">Your Name</label>
                                    </div>
                                  
                                </div>
                                <div className="col-md-6">
                                <span className="text-danger">*</span>
                                    <div className="form-floating">
                                   
                                    
                                        <input type="email" className="form-control border-0"  placeholder="Your Email"   value={email} onChange={(e)=>setEmail(e.target.value)} required/>
                                        <label for="email">Your Email</label>
                                     
                                    </div>
                                    
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input type="text" className="form-control border-0"  placeholder="Subject"  value={subject} onChange={(e)=>setSubject(e.target.value)} required/>
                                        <label for="subject">Subject</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea className="form-control border-0" placeholder="Leave a message here"  value={messages} onChange={(e)=>setMessages(e.target.value)} style={{height: "160p"}}></textarea>
                                        <label for="message">Message</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                
                                    <button className="btn btn-primary w-100 py-3" type="submit" disabled={isLoading} >
                                    {isLoading ? 'Loading...' : 'Submit'}
                                    </button>
                                </div>
                            </div>
                       </form>
                    </div>
 
                </div>
            </div>
        </div>
        {/*-- Contact End */}

         {/*   <Team/> */}
        {/*-- Subscribe End */}
        <Footer/>
        </Fragment>
    )
}

export default Contact; 