// NotFound.js

import React from 'react';

const NotFound = () => {
  return (
    <div>
             <div className="container-fluid ExploreTour py-5">
        <div className="container-fluid about py-5">
            <div className="container">
                <div className="row g-5 align-items-center text-center">
      <h2 className='text-danger'>404 - Not Found</h2>
      <p>The page you're looking Not Found.</p>
    </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default NotFound;