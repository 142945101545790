import React, {  Fragment, useEffect, useState } from "react";
import Footer from "./Footer";
import Menu from "./Menu";
import TopBanner from "../Components/TopBanner";
import agent from "../Components/api/agent"

export default function Privacy(){

    const [data,setData]=useState([]);

    useEffect(()=>{
      getData();
     
    },[])
    
    const getData = ()=> {
     agent.Privacy.list().then(response=>{
      setData(response);
  })
    }

     return(
        <Fragment>
            <Menu/>
            <TopBanner/>
            <div className="container-fluid bg-oURService">
            <div className="container text-center py-5" style={{maxwidth: "900px"}}>
                <h3 className="text-white display-3 mb-4">Privacy Policy</h3>
  
            </div>
        </div>
        {/*-- Header End */}
                   {/*-- Privacy Start */}
        <div className="container-fluid about py-5">
            <div className="container py-5">
                <div className="row g-12 align-items-center">

                    <div className="col-lg-12" style={{background: `linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .8))`, backgroundImage: `url(img/about-img-1.png)`}}>
                      
                        <p className="mb-4 " >
                        <div dangerouslySetInnerHTML={{__html: data.remarks}} />
                       
                        </p>

                    </div>
                </div>
            </div>
        </div>
        {/*-- Privacy End */}
           <Footer/>
        </Fragment>
     )
}