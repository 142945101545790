
import React, {  Fragment,useEffect, useState  } from "react";

import { useParams } from "react-router-dom";
import Menu from "./Menu";
import TopBanner from "../Components/TopBanner";
import Footer from "./Footer";
import agent from "../Components/api/agent";
function Intoffice()

{
    const param = useParams();
    const [data, setData] = useState([]);
    const [id, setId] = useState(param.id || 0); // Initialize id state with param.id

    useEffect(() => {
        getData();
    }, [id]); // Add id as a dependency for the useEffect hook

const getData=()=> {
    agent.AboutUs.Details(param.id).then(response=>{
        setData(response);
    })
}
    // Function to update id state when param.id changes
    useEffect(() => {
        setId(param.id || 0);
    }, [param.id]);

return(
    <Fragment>
        <Menu/>
        <TopBanner/>
        <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-2" style={{maxwidth: "900px"}}>
                <h3 className="text-white display-3 mb-4">{data.title}</h3>
                <ol className="breadcrumb justify-content-center mb-0">
                </ol>    
            </div>
        </div>
        {/*-- Header End */}
  {/*-- Contact Start */}
        <div className="container-fluid contact bg-light py-5">
            <div className="container py-5">
                <div className="row g-5 ">
                    <div className="col-lg-4">
                        <div className="bg-white rounded p-4">
                            <div className="text-center mb-4">
                                <i className="fa fa-map-marker-alt fa-3x text-primary"></i>
                                <h4 className="text-primary">Address</h4>
                                <p className="mb-0">{data.address}</p>
                            </div>
                            <div className="text-center mb-4">
                                <i className="fa fa-phone-alt fa-3x text-primary mb-3"></i>
                                <h4 className="text-primary">Mobile</h4>
                                <p className="mb-0">{data.phone}</p>
                                
                            </div>
                           
                            <div className="text-center">
                                <i className="fa fa-envelope-open fa-3x text-primary mb-3"></i>
                                <h4 className="text-primary">Email</h4>
                                <p className="mb-0">{data.email}</p>
                             
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                            <h1 className="mb-4">Welcome to <span className="text-primary"></span>{data.title}</h1>
                            <p className="mb-4 " >
                            <div dangerouslySetInnerHTML={{__html: data.remarks}} />
                        
                            </p>
                    </div>
                </div>
            </div>
        </div>
        {/*-- Contact End */}
    <Footer/>
</Fragment>
)
   

}

export default Intoffice;
