
import React, { Fragment, useEffect, useState }  from "react";
import Menu from "../Pages/Menu";
import Footer from "../Pages/Footer";
import Table from'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import agent from "./api/agent";

function OfficeList(){
        {/* Modal start  */}
        const [show, setShow] = useState(false);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
      
       {/*Insert Section feilds*/}
       const [name,setName]=useState('');
       const [phone,setPhone]=useState('');
       const[email,setEmail]=useState('');
       const[website,setWebsite]=useState();
       const[address,setAddress]=useState('');
       const[remarks,setRemarks]=useState('');

       {/*Update section feilds*/}
       const[editId,setEditId]=useState('');
       const [editname,setEditName]=useState('');
       const [editphone,setEditPhone]=useState('');
       const[editemail,setEditEmail]=useState('');
       const[editwebsite,setEditWebsite]=useState('');
       const[editaddress,setEditAddress]=useState('');
       const[editremarks,setEditRemarks]=useState('');



    const [data,setData]=useState([]);
  
    useEffect(()=>{
        getData();
    },[])

    const getData = ()=> {
       agent.Officelist.list().then(response=>{
        setData(response);
       })
    }
    const handleEdit=(id)=>{
        handleShow();
        axios.get(`https://localhost:44315/api/Office/${id}`)
        .then((result)=>
        {
        setEditName(result.data.name);
        setEditPhone(result.data.phone);
        setEditEmail(result.data.email);
        setEditAddress(result.data.address);
        setEditWebsite(result.data.website);
        setEditRemarks(result.data.remarks);
        setEditId(id);


        }).catch((error)=>{
          toast.error(error);
        })

    }
    const handleDelete=(id)=>{
        if(window.confirm("Are you sure delete this office .."+id)==true)
        {
            axios.delete(`https://localhost:44315/api/Office/${id}`)
            .then((result)=>{
            if(result.status===200)
            {
             
               toast.success('Office has been successfully Deleted');
               getData();
            }
          }).catch((error)=>{
            toast.error(error);
          })

        }
       
    }
    const handleUpdate=()=>{
       const url=`https://localhost:44315/api/Office/${editId}`;
       const data={
        "id":editId,
        "name":editname,
        "phone":editphone,
        "address":editaddress,
        "email": editemail,
        "website": editwebsite,

        "remarks": editremarks

       }
       axios.put(url,data)
       .then((result)=>{
        getData();
        clear();
        toast.success('Office has been updated successfully')
        handleClose();
       }).catch((error)=>
       {
        toast.error(error);
       })
       
    }
    const handleSave=()=>{
       const url='http://172.17.3.60:8030/api/Office';
       const data={
        "name": name,
        "address": address,
        "email": email,
        "phone": phone,
        "website": website,
        "remarks": remarks
       }
       axios.post(url,data)
       .then((result)=>{
        getData();
        clear();
        toast.success('Office has successfully added');
       }).catch((error)=>{
        toast.error(error);
       })
    }
    const clear=()=>{
        setName('');
        setAddress('');
        setPhone('');
        setEmail('');
        setWebsite('');
        setRemarks('');

        setEditId('');
        setEditName('');
        setEditPhone('');
        setEditEmail('');
        setEditRemarks('');
        setEditWebsite('');

    }
    return(
        
       <Fragment>

<Menu/>

       
        <div className="container-fluid contact bg-light py-5">
            <div className="container py-5">
       

<ToastContainer/>
<Container>
<h1>Office List</h1>
      <Row>
        <Col>
        <input type="text" placeholder="Name" className="form-control" value={name} onChange={(e)=>setName(e.target.value)}  required/>
        </Col>
        <Col> 
        <input type="Number" placeholder="Phone" className="form-control" value={phone} onChange={(e)=>setPhone(e.target.value)}/>
        </Col>
        <Col>
        <input type="text" placeholder="Email" className="form-control" value={email} onChange={(e)=>setEmail(e.target.value)}/>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col>
        <input type="text" placeholder="Website" className="form-control" value={website} onChange={(e)=>setWebsite(e.target.value)}/>
        </Col>
        <Col>
        <input type="text" placeholder="Address" className="form-control" value={address} onChange={(e)=>setAddress(e.target.value)}/>
        </Col>
        <Col>
        <input type="text" placeholder="Remarks" className="form-control" value={remarks} onChange={(e)=>setRemarks(e.target.value)}/>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col>
        <Button variant="secondary"  onClick={()=>handleSave()}>Save</Button>
        </Col>
       
      </Row>
  
    <br/>
    <Table striped bordered  hover >
<thead>
    <tr>
        <th>Id</th>
        <th>Name</th>
        <th>Address</th>
        
        <th>Phone</th>
        <th>Website</th>
        <th>Remarks</th>

        <th>Action</th>

    </tr>
</thead>
<tbody>
    {
        data && data.length>0 ?
        data.map((item,index )=>
        {

            return(
            <tr key={index}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.address}</td>
            <td>{item.phone}</td>
            <td>{item.website}</td>

            <td>{item.remarks}</td>
            <td>
                <Button variant="danger" onClick={()=> handleDelete(item.id)}>Delete</Button>
                |
                <Button variant="primary" onClick={()=> handleEdit(item.id)}>Edit</Button>
               
            </td>
    
    
        </tr>
        )
        })
        :
        'Lodding....'
    }

</tbody>
    </Table>
    </Container>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modify /Update Office</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
        <Col>
        <input type="text" placeholder="Name" className="form-control" value={editname} onChange={(e)=>setEditName(e.target.value)}/>
        </Col>
        <Col> 
        <input type="Number" placeholder="Phone" className="form-control" value={editphone} onChange={(e)=>setEditPhone(e.target.value)}/>
        </Col>
        <Col>
        <input type="text" placeholder="Email" className="form-control" value={editemail} onChange={(e)=>setEditEmail(e.target.value)}/>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col>
        <input type="text" placeholder="Website" className="form-control" value={editwebsite} onChange={(e)=>setEditWebsite(e.target.value)}/>
        </Col>
        <Col>
        <input type="text" placeholder="Address" className="form-control" value={editaddress} onChange={(e)=>setEditAddress(e.target.value)}/>
        </Col>
        <Col>
        <input type="text" placeholder="Remarks" className="form-control" value={editremarks} onChange={(e)=>setEditRemarks(e.target.value)}/>
        </Col>
      </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
            </div>
        </div>
        
<Footer/>

       </Fragment>
        
    )
}

export default OfficeList