
import React, { Fragment,useEffect, useState  } from "react";
import Footer from "./Footer";
import Menu from "./Menu";
import axios from "axios";
import { Link } from "react-router-dom";
import agent from "../Components/api/agent";
import Team from "../Components/Team";
import TopBanner from "../Components/TopBanner";

function About()
{

    const [data,setData]=useState([]);

    const [group,setGroup]=useState([]);
 
    useEffect(()=>{
        getData();
         getGroup();
     
    },[])

    const getData = ()=> {
       // axios.get('https://localhost:44315/api/AboutUs')
       // .then((result)=>{
         //   setData(result.data)
        
       // })
       // .catch((error)=>{
        //    console.log(error)
        //})
        //با استفاده از خط بالا هم میتوان دیتا را دریافت نمود ولی داخل یک فایل تمام درخواستی را ثبت کردیم
        agent.AboutUs.list().then(response=>{
            setData(response);
        })

    }
    const getGroup=()=>{
        agent.Group.list().then(response=>{
            setGroup(response);
        })
    }
    return(
        <Fragment>
            
                      {/*-- Template Stylesheet */}

            <Menu />
            {/*-- Header Start */}
            <TopBanner/>
        <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5" style={{maxwidth: "900px"}}>
                <h3 className="text-white display-3 mb-4">About Us</h3>
                <ol className="breadcrumb justify-content-center mb-0">
                </ol>    
            </div>
        </div>
        {/*-- Header End */}
                   {/*-- About Start */}
        <div className="container-fluid about py-5">
            <div className="container py-5">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-5">
                        <div className="h-100" style={{border: '50px solid', borderColor: 'transparent #13357B transparent #13357B'}}>
                         <img src="assets/img/about-img-1.png" className="img-fluid w-100 h-100" alt=""/>
                            
                        </div>
                    </div>
                    <div className="col-lg-7" style={{background: `linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .8))`}}>
                        <h5 className="section-about-title pe-3">About Us</h5>
                        <h1 className="mb-4">Welcome to <span className="text-primary">SkyTravels</span></h1>
                       
             

                        <p className="mb-4 LongTextThreeDotAboutSectionBody" >
                        <div dangerouslySetInnerHTML={{__html: data.remarks}} />
                       
                        </p>
                       
                        <Link to="/AboutDetails" className="btn btn-primary rounded-pill py-3 px-5 mt-2"> Read More</Link>
                    </div>
                </div>
            </div>
        </div>
        {/*-- About End */}
        <div className="container-fluid bg-oURService">
            <div className="container  py-5" style={{maxwidth: "900px"}}>
                <h3 className="text-white display-3 mb-4"></h3>
                <div className="row g-5 ">
                    {
                        group.map((item,index)=>
                        {
                            return(
                                <div className="col-lg-3">
                                
                                    <div className="bg-white rounded p-4" style={{height:"100%"}}>
                                        
                                        <div className="text-left mb-4">
                                                                                      
                                            <div className="ThreeDotText"  dangerouslySetInnerHTML={{__html: item.description}}/>
                                        </div>
                                                                         
                                  
                                    </div>
                                   
                                </div>

                            )
                        })
                    }
            

                    </div>

        </div>
        </div>

        {/*-- Travel Guide Team Start */}
       {/* <Team/>*/}
        
        {/*-- Travel Guide Team End */}

           
        <Footer />
        </Fragment>
    )
}

export default About; 