
import axios, { AxiosResponse } from "axios";

axios.defaults.baseURL="https://Admin.skytravels.com/api";
//axios.defaults.baseURL="http://localhost:5257/api";
//axios.defaults.baseURL="http://localhost:809/api";
const responseBody=(response:AxiosResponse)=>response.data;

const requests={
    get:(url:string)=>axios.get(url).then(responseBody),
    post:(url:string,body:{})=>axios.post(url,body).then(responseBody),
    put:(url:string,body:{})=>axios.put(url,body).then(responseBody),
    del:(url:string)=>axios.delete(url).then(responseBody),
}
const AboutUs={
    list:()=>requests.get('/AboutUs'),
    Details:(id:string)=>requests.get(`/AboutUs/${id}/details`)
}

const Branches={
    list:()=>requests.get('/AboutUs/Branches')
}
const Banner={
    list:()=>requests.get('/Banner')
}
const Officelist={
    list:()=>requests.get('/Office')
}
const Team={
    list:()=>requests.get('/Team')
}
const OurClient={
    list:()=>requests.get('/OurClient')
}
//News 
const News={
    list:()=>requests.get('/News'),
    Details:(id:string)=>requests.get(`/News/${id}`),
    NewsGallery:(id:string)=>requests.get(`/News/${id}/Gallery`),
    NewsGroup:(id:string)=>requests.get(`/News/${id}/GetByGroupId`),
}
const EventById={
    list:()=>requests.get('/News/GetByGroupId')
}
//Our Services
const OurServices={
    list:()=>requests.get('/OurServices'),
    OurServicessByid:(id:string)=>requests.get(`/OurServices/${id}/Servicess`),
}
const Privacy={
    list:()=>requests.get('/Privacy')
}
const Group={
    list:()=>requests.get('/Group')
}
const Genralinfo={
    list:()=>requests.get('/Genralinfo')
}
//Packages
const TourPackage={
    list:()=>requests.get('/TourPackage'),
    Details:(id:string)=>requests.get(`/TourPackage/${id}`),
    PackageGallery:(id:string)=>requests.get(`/TourPackage/${id}/details`),
    PackagePrice:(id:string)=>requests.get(`/TourPackage/${id}/Price`)

}

const agent={
    AboutUs,
    Banner,
    Officelist,
    Team,
    OurClient,
    News,
    EventById,
    OurServices,
    Privacy,
    Branches,
    Group,
    Genralinfo,
    TourPackage
}

export default agent;