import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import agent from "../Components/api/agent";
import "../Components/menu.css";
function TopBanner() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    agent.OurServices.list().then((response) => {
      setData(response);
    });
  };

  return (
    <Fragment>
       <div className="bg-primary mt-n2 ">
        <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light position-relative pb-0">
          
                <div className="collapse navbar-collapse bg-primary ms-auto" >
                    <div className="navbar-nav ">
                 
                
                    <Link to="/" className="nav-item nav-link  text-light ">
                  Home
                </Link>

                <ul className="navbar-nav ms-auto py-0">
                  {/* segestion Menu
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle text-light"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      About
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link to="/About" className="dropdown-item  ">
                          Sky Travels
                        </Link>
                      </li>

                      <ul>
                        <Link to="#" className="dropdown-item InternationalMenu">
                          International offices
                        </Link>
                        <li></li>
                        <li>
                          <Link to={`/Intoffice/${4}`} className="dropdown-item ">
                            UAE
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={`/Intoffice/${5}`} className="dropdown-item ">
                            GERMANY
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={`/Intoffice/${6}`} className="dropdown-item ">
                            TURKEY
                          </Link>
                        </li>
                      </ul>
                      <li>
                        {" "}
                        <Link to="/OurClients" className="dropdown-item ">
                          Our Clients
                        </Link>
                      </li>
                    </ul>
                  </li>
                  */}
                        
            <li className="nav-item dropdown">
                <a href="#" id="menu" data-bs-toggle="dropdown" className="nav-link dropdown-toggle text-light" data-bs-display="static">About Us</a>
                <ul className="dropdown-menu">
                    <li>
                        <Link to="/About" className="dropdown-item">Sky Travels</Link>
                    </li>
                    <li className="dropdown-submenu">
                        <a href="#" className="dropdown-item dropdown-toggle">International offices</a>
                        <ul className="dropdown-menu">
                            <li>
                                <Link to={`/Intoffice/${4}`} className="dropdown-item">UAE</Link>
                            </li>
                            <li>
                                <Link to={`/Intoffice/${5}`} className="dropdown-item">GERMANY</Link>
                            </li>
                            <li>
                                <Link to={`/Intoffice/${6}`} className="dropdown-item">TURKEY</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/OurClients" className="dropdown-item">Our Clients & Partner</Link>
                    </li>
                </ul>
            </li>
       
     
                </ul>
               {/*
                <Link to="/Packages" className="nav-item nav-link  text-light">
                  Packages
                </Link>
                */}
                <ul className="navbar-nav ms-auto py-0">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle text-light "
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Services
                    </a>
                    <ul className="dropdown-menu">
                      {data.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/OurServices/${item.id}`} className="dropdown-item  ">
                              {item.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
               
                  </div>
               

                 
          
          </div>
          </nav>
          </div>
        </div>
     
    </Fragment>
  );
}
export default TopBanner;
