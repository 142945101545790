import React, { Fragment,useEffect, useState  } from "react";
import { Link } from "react-router-dom";
import agent from "../Components/api/agent";

function Menu(){
    
       {/*Using Logo*/}
    const [data,setData]=useState([]);


  
    useEffect(()=>{
       agent.AboutUs.list().then(response=>{
        setData(response);
       })
     
    },[])

    {/*Using Ourservices Menu*/}

    const [dataservice,setDataservice]=useState([]);

    useEffect(()=>{
        getDataservice();
     
    },[])

    const getDataservice = ()=> {
        agent.OurServices.list().then(response=>{
            setDataservice(response);
        })
    }

    return(
       <Fragment>

       

        {/*-- Navbar & Hero Start --*/}
        <div className="container-fluid position-relative p-0">
            <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
            <div className="Container">
                <Link to="/" className="navbar-brand p-0">
                    <img src="/assets/img/logo1.png" className="img-fluid w-100 rounded-top" />
                    </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">

                      {/*Begin Using fo mobile device*/}
                     <span className="TopMenuCustom">
                      <Link to="/" className="nav-item nav-link text-light">Home</Link>
    
                      <li className="nav-item dropdown">
                  
                  <a href="#" id="menu" data-bs-toggle="dropdown" className="nav-link dropdown-toggle text-light" data-bs-display="static">About Us</a>
                  <ul className="dropdown-menu">
                  <Link to="/About" className="dropdown-item  ">
                   Sky Travels
                 </Link>
                 <Link to="/OurClients" className="dropdown-item ">
                        Our Clients & Partner
                      </Link>
                      <li className="dropdown-submenu">
                          <a href="#" data-bs-toggle="dropdown" className="dropdown-item dropdown-toggle"> International offices</a>
                          <ul className="dropdown-menu">
                          <li>
                        <Link to={`/Intoffice/${4}`} className="dropdown-item ">
                          UAE
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to={`/Intoffice/${5}`} className="dropdown-item ">
                          GERMANY
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to={`/Intoffice/${6}`} className="dropdown-item ">
                          TURKEY
                        </Link>
                      
                      </li>
                          </ul>
                      </li>
                   
                  </ul>
                
              </li>

                     
                     {/*   <Link to="/Packages" className="nav-item nav-link text-light ">Packages</Link>   */} 

                        <ul className="navbar-nav ms-auto py-0">
                    <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle text-light" role="button" 
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Services
                        </a>
                        <ul className="dropdown-menu">
                        {
                dataservice.map((item,index )=>
                    {

                    return(
                    
                       
                   <li key={index}>
                    <Link to={`/OurServices/${item.id}`} className="dropdown-item ">{item.title}</Link>
                    </li>

                    
                  )

                   } )
                  
                  }
                    </ul>
                   </li>

            </ul>
                        </span>
                    
                  {/*End Using fo mobile device*/}
                  {/*
                  <ul className="navbar-nav py-0   ">
                    <li className="nav-item dropdown ">
                    <a className="nav-link dropdown-toggle" role="button" 
                            data-bs-toggle="dropdown" aria-expanded="false">
                      Event
                    </a>
                    <ul className="dropdown-menu">

                            <Link to="/News/1" className="dropdown-item  ">
                             News
                            </Link>
                            <Link to="/Events/2" className="dropdown-item  ">
                             Event
                            </Link>
                         
                    </ul>
                  </li>
                </ul>
                */}
           
                        <Link to="/Contact" className="nav-item nav-link " >Contact Us</Link> 

     
                    </div>
                  
                    <ul className="navbar-nav py-0   ">
                    <li className="nav-item dropdown ">
                    <a className="text-light nav-link dropdown-toggle btn btn-primary rounded-pill btn-sm  py-2 px-3 ms-lg-3  " role="button" 
                            data-bs-toggle="dropdown" aria-expanded="false">
                      Online Portals
                    </a>
                    <ul className="dropdown-menu">


                          <Link to="https://kbl.skytravellers.com/indexpage.jsp" target="_blank" className="dropdown-item  ">
                            Afghanistan Portal
                            </Link>
                            <Link to="https://app.skytravellers.com/login" target="_blank" className="dropdown-item  ">
                            Germany Portal
                            </Link>
                            <Link to="https://uae.skytravellers.com/indexpage.jsp" target="_blank" className="dropdown-item  ">
                            UAE Portal 
                            </Link>
                        
                    </ul>
                  </li>
                </ul>

                </div>
                </div>
            </nav>
            </div>
       </Fragment>
    )
}

export default Menu;