
import React, {  Fragment, useEffect, useState } from "react";
import Menu from "./Menu";
import TopBanner from "../Components/TopBanner";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import agent from "../Components/api/agent";
 function NewsDetails()
{

const param= useParams();


const [data,setData]=useState([]);
const [image,setGallery]=useState([]);

 
useEffect(()=>{
    getData();
    getGallery();
},[])
const getData = ()=> {
agent.News.Details(param.id).then(response=>{
    setData(response);
})
}

const getGallery=()=> {
    agent.News.NewsGallery(param.id).then(response=>{
        setGallery(response);
    })
}


    return(
        <Fragment>
      <Menu/>
      <TopBanner/>
      <div className="container-fluid bg-oURService">
            <div className="container text-center py-2" style={{maxwidth: "900px"}}>
                <h3 className="text-white display-3 mb-4">News</h3>
  
            </div>
        </div>
        <div className="container-fluid gallery py-5 my-5">
              <div className="tab-class">

                <div className="tab-content">
                    <div id="GalleryTab-1" className="tab-pane fade show p-0 active">
                        <div className="row g-2">
                        <h3>{data.title}</h3>
                        {
                image.map((item,index )=>
              {

            return(
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-2" key={index}>
                <div className="gallery-item h-100">
                    <img src={item.imageSrc} className="img-fluid w-100 h-100 rounded" alt="Image"/>
                    <div className="gallery-content">
                        <div className="gallery-info">
                          
                        </div>
                    </div>
                    <div className="gallery-plus-icon">
                        <a href={item.imageSrc} data-lightbox="gallery-1" className="my-auto"><i className="fas fa-plus fa-2x text-white"></i></a>
                    </div>
                </div>

        </div>
                  )
                }
                )}
                         </div>
                          </div>
                       
   
          </div>
        
           </div>
           <br/>
           <br/>
           <span className="d-flex justify-content-center align-items-end">
            <Link to="/"  class="btn btn-primary rounded-pill py-2 px-4">Back</Link>
            
           </span>
      </div>
                   
<Footer/>
        </Fragment>
    )
}

export default NewsDetails;
